<template>
    <div class="homecontainer _homecontainer">
        <!-- <GoodBriefInfo
            :goodname="''"
            :goodinfo="'开运红绳,吉祥好意头'"
            :fontcolor="'black'"
            :backgoundcolor="'rgb(19, 18, 25)'"
            :imgsrc="require('./imgs/p1.jpg')"
        />
        <GoodBriefInfo
            :goodname="''"
            :goodinfo="'旺缘红绳手链'"
            :fontcolor="'white'"
            :backgoundcolor="'rgb(250, 250, 250)'"
            :imgsrc="require('./imgs/p2.jpg')"
        />
        <GoodBriefInfo
            :goodname="''"
            :goodinfo="'一品茶香,福寿无疆'"
            :fontcolor="'white'"
            :backgoundcolor="'rgb(250, 250, 250)'"
            :imgsrc="require('./imgs/p3.jpg')"
        />
        <div class="subdiv _subdiv">
            <div class="left">
                <GoodBriefInfo
                    :goodname="''"
                    :goodinfo="'大境人生，典藏古玩'"
                    :backgoundcolor="'rgb(250, 250, 250)'"
                    :fontcolor="'white'"
                    :imgsrc="require('./imgs/p4.jpg')"
                />
            </div>
            <div class="right">
                <GoodBriefInfo
                    :goodname="''"
                    :goodinfo="'翰墨寄真情，丹青颂伟人'"
                    :fontcolor="'white'"
                    :backgoundcolor="'rgb(19, 18, 25)'"
                    :imgsrc="require('./imgs/p5.jpg')"
                />
            </div>
        </div>
        <div class="subdiv _subdiv">
            <div class="left">
                <GoodBriefInfo
                    :goodname="''"
                    :goodinfo="'超凡品质，尽显奢华'"
                    :backgoundcolor="'rgb(0, 0, 0)'"
                    :fontcolor="'white'"
                    :imgsrc="require('./imgs/p6.jpg')"
                />
            </div>
            <div class="right">
                <GoodBriefInfo
                    :goodname="''"
                    :goodinfo="'豪庭雅苑，华都相伴'"
                    :fontcolor="'white'"
                    :backgoundcolor="'rgb(251, 251, 253)'"
                    :imgsrc="require('./imgs/p7.jpg')"
                />
            </div>
        </div> -->
        <div class="wrap">
            <div class="topBig">
                <div class="photo">
                    <img src="../../assets/login.jpg" alt="" />
                </div>
                <div class="intro">APP登录界面，<br />手机号一键注册登录</div>
            </div>

            <div class="topBig">
                <div class="intro" style="padding-right: 20px">
                    视频添加背景效果如图，点击添加背景，选择背景模糊后，背景添加完成
                </div>
                <div class="photo">
                    <img src="../../assets/outLogin.jpg" alt="" />
                </div>
            </div>
            <!-- 宫格 -->
            <div class="grie">
                <div class="itemBox">
                    <div class="photo"><img src="../../assets/gerenzhongxin.jpg" alt="" /></div>
                    <div class="intro">
                        视频添加边框操作，根据视频尺寸，拖动进度条，对应的位置会出现边框，将边框添加到满意的位置即可。
                    </div>
                </div>
                <div class="itemBox">
                    <div class="photo"><img src="../../assets/add.jpg" alt="" /></div>
                    <div class="intro">App首页，点击加号进行视频的制作</div>
                </div>
                <div class="itemBox">
                    <div class="intro">裁剪视频尺寸界面，调整完视频尺寸后，点击制作</div>
                    <div class="photo"><img src="../../assets/five.jpg" alt="" /></div>
                </div>
                <div class="itemBox">
                    <div class="intro">
                        视频编辑界面，视频裁剪后，跟据教程所学的内容进行制作视频，视频制作后，点击保存到相册。
                    </div>
                    <div class="photo"><img src="../../assets/six.jpg" alt="" /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoodBriefInfo from './subcomponents/GoodBriefInfo';

export default {
    name: '',
    components: {
        GoodBriefInfo,
    },
};
</script>

<style lang="less" scoped>
// 大于800px
@media only screen and (min-width: 800px) {
    .homecontainer {
        font-size: 1vw;
        .wrap {
            width: 80%;
            margin: 0 auto;
            .topBig {
                width: 100%;
                background-color: #333;
                padding: 20px 50px;
                box-sizing: border-box;
                display: flex;
                margin-top: 1px;
                .photo {
                    width: 30%;
                    height: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        transition: all 0.5s;
                    }
                    &:hover img {
                        transform: scale(1.1);
                    }
                }
                .intro {
                    width: 70%;
                    padding-top: 100px;
                    text-align: center;
                    color: #fff;
                    font-size: 40px;
                    line-height: 80px;
                }
            }
            .grie {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .itemBox {
                    width: 49%;
                    margin-top: 20px;
                    background-color: #333;
                    box-sizing: border-box;
                    padding: 20px;
                    display: flex;
                    .photo {
                        width: 30%;
                        height: 100%;
                        overflow: hidden;
                        img {
                            width: 100%;
                            transition: all 0.5s;
                        }
                        &:hover img {
                            transform: scale(1.1);
                        }
                    }
                    .intro {
                        width: 70%;
                        text-align: center;
                        color: #fff;
                        font-size: 30px;
                        line-height: 70px;
                        padding: 10px;
                    }
                }
            }
        }
    }
}

// 小于800px
@media only screen and (max-width: 800px) {
    ._homecontainer {
        font-size: 1vw;
        .wrap {
            width: 100%;
            margin: 0 auto;
            .topBig {
                width: 100%;
                background-color: #333;
                .photo {
                    width: 20%;
                    height: 100%;
                    margin: 0 auto;
                    img {
                        width: 100%;
                        transition: all 0.5s;
                    }
                    &:hover img {
                        transform: scale(1.1);
                    }
                }
                .intro {
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    padding: 20px 0;
                }
            }
            .grie {
                width: 100%;
                .itemBox {
                    width: 100%;
                    margin-top: 20px;
                    background-color: #333;
                    box-sizing: border-box;
                    padding: 20px;
                    display: flex;
                    .photo {
                        width: 30%;
                        height: 100%;
                        overflow: hidden;
                        img {
                            width: 100%;
                            transition: all 0.5s;
                        }
                        &:hover img {
                            transform: scale(1.1);
                        }
                    }
                    .intro {
                        width: 70%;
                        text-align: center;
                        color: #fff;
                        font-size: 25px;
                        line-height: 50px;
                        padding: 10px;
                    }
                }
            }
        }
    }
}
</style>
