<template>
    <div class="homecontainer _homecontainer">
        <div v-if="num == 0">
            <textarea placeholder="请填写您的描述......" v-model="content"></textarea>
            <div style="width: 30%; height: 40px; margin: 0 auto">
                <button style="width: 80px; height: 100%" @click="fanhui">返回</button>
                <button style="width: 80px; height: 100%; margin-left: 20px" @click="click">
                    提交
                </button>
            </div>
        </div>
        <div v-else class="font">感谢你的反馈,我们会尽快和您联系</div>
    </div>
</template>

<script>
import GoodBriefInfo from './subcomponents/GoodBriefInfo';

export default {
    name: '',
    components: {
        GoodBriefInfo,
    },
    data() {
        return {
            content: '',
            num: 0,
        };
    },
    methods: {
        fanhui() {
            this.$router.go(-1);
        },
        click() {
            if (!this.content) {
                alert('请输入内容后提交');
                return;
            }
            this.num = 1;
        },
    },
};
</script>

<style lang="less" scoped>
// 大于800px
@media only screen and (min-width: 800px) {
    .homecontainer {
        height: 100vh;
        font-size: 1vw;
        .subdiv {
            width: 100%;
            display: flex;
            justify-content: space-around;
            font-size: 0.5em;
            .left {
                width: 48%;
                height: 100%;
                font-size: 0.8em;
            }
            .right {
                width: 48%;
                height: 100%;
                font-size: 0.8em;
            }
        }
    }
}
textarea {
    width: 80%;
    height: 50vh;
    margin-top: 30px;
    padding: 20px;
    font-size: 20px;
}
// 小于800px
@media only screen and (max-width: 800px) {
    ._homecontainer {
        font-size: 1vw;
        ._subdiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            font-size: 0.5em;
            .left {
                width: 100%;
                height: 100%;
                font-size: 0.8em;
            }
            .right {
                width: 100%;
                height: 100%;
                font-size: 0.8em;
            }
        }
    }
}
.font {
    margin-top: 20px;
    font-size: 30px;
}
</style>
